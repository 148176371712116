<template>
    <div class="wrapper accountsPayable">
        <table class="custom-table">
            <thead>
                <tr>
                    <td class="custom-th custom-th-bg" style="min-width:.6rem">
                        序号
                    </td>
                    <td class="custom-th custom-th-bg" style="width:1.4rem;">
                        品种
                    </td>
                    <td class="custom-th custom-th-bg" style="width:2rem;">
                        项目
                    </td>
                    <template v-for="(item, index) in totalData">
                        <td class="custom-th custom-th-bg mongth-th" :key="'th'+index" v-if="item.totalInfo.date>0">
                            {{ item.totalInfo.date }}月
                        </td>
                        <td class="custom-th custom-th-bg mongth-th" :key="'th'+index" v-if="item.totalInfo.date==0">
                            总计
                        </td>
                    </template>
                </tr>
            </thead>
            <tbody style="border-bottom:1px solid #eef0f7">
                <tr class="border6">
                    <td class="custom-th custom-th-odd" style="min-width:.6rem;">
                        1
                    </td>
                    <td class="custom-th">
                        应付账款--总计
                    </td>
                    <td class="padding0">
                        <table class="custom-table inner-table">
                            <tbody>
                                <tr>
                                    <td class="td-class1">
                                        期初余额（万元）
                                    </td>
                                </tr>
                                <tr>
                                    <td class="td-class2">
                                        其中：中联内应付
                                    </td>
                                </tr>
                                <tr>
                                    <td class="td-class1">
                                        实际采购（万元）
                                    </td>
                                </tr>
                                <tr>
                                    <td class="td-class2">
                                        其中：中联内采购
                                    </td>
                                </tr>
                                <tr>
                                    <td class="td-class1">
                                        实际付款（万元）
                                    </td>
                                </tr>
                                <tr>
                                    <td class="td-class2">
                                        现金（万元）
                                    </td>
                                </tr>
                                <tr>
                                    <td class="td-class2">
                                        承兑（万元）
                                    </td>
                                </tr>
                                <tr>
                                    <td class="td-class2">
                                        抵账（万元）
                                    </td>
                                </tr>
                                <tr>
                                    <td class="td-class2">
                                        其它（万元）
                                    </td>
                                </tr>
                                <tr>
                                    <td class="td-class2">
                                        其中：中联内付款
                                    </td>
                                </tr>
                                <tr>
                                    <td class="td-class3">
                                        现金（万元）
                                    </td>
                                </tr>
                                <tr>
                                    <td class="td-class3">
                                        承兑（万元）
                                    </td>
                                </tr>
                                <tr>
                                    <td class="td-class3">
                                        抵账（万元）
                                    </td>
                                </tr>
                                <tr>
                                    <td class="td-class3">
                                        其它（万元）
                                    </td>
                                </tr>
                                <tr>
                                    <td class="td-class1">
                                        期末余额（万元）
                                    </td>
                                </tr>
                                <tr>
                                    <td class="td-right">
                                        付款周期：1月内
                                    </td>
                                </tr>
                                <tr>
                                    <td class="td-right">
                                        3月内
                                    </td>
                                </tr>
                                <tr>
                                    <td class="td-right">
                                        6月内
                                    </td>
                                </tr>
                                <tr>
                                    <td class="td-right">
                                        其中：中联内应付
                                    </td>
                                </tr>
                                <tr>
                                    <td class="td-class1">
                                        付款比例（％）
                                    </td>
                                </tr>
                                <tr>
                                    <td class="td-class1">
                                        应付减少(万元)
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </td>
                    <template v-for="(item, index) in totalData">
                        <td :key="'total'+index" class="padding0">
                            <table class="custom-table inner-table">
                                <tbody>
                                    <tr>
                                        <td @click="showDataDrills(item.totalInfo,1,1,null)" v-if="isDrill">
                                            <div><a href="javascript:;">{{ item.totalInfo.balance_begin }}</a></div>
                                        </td>
                                        <td v-else>
                                            <div>{{ item.totalInfo.balance_begin }}</div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td @click="showDataDrills(item.totalInfo,2,1,null)" v-if="isDrill">
                                            <div><a href="javascript:;">{{ item.totalInfo.balance_begin_inner }}</a></div>
                                        </td>
                                        <td v-else>
                                            <div>{{ item.totalInfo.balance_begin_inner }}</div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td @click="showDataDrills(item.totalInfo,3,1,null)" v-if="isDrill">
                                            <div><a href="javascript:;">{{ item.totalInfo.procure_actual }}</a></div>
                                        </td>
                                        <td v-else>
                                            <div>{{ item.totalInfo.procure_actual }}</div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td @click="showDataDrills(item.totalInfo,4,1,null)" v-if="isDrill">
                                            <div><a href="javascript:;">{{ item.totalInfo.procure_actual_inner }}</a></div>
                                        </td>
                                        <td v-else>
                                            <div>{{ item.totalInfo.procure_actual_inner }}</div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td @click="showDataDrills(item.totalInfo,5,1,null)" v-if="isDrill">
                                            <div><a href="javascript:;">{{ item.totalInfo.pay }}</a></div>
                                        </td>
                                        <td v-else>
                                            <div>{{ item.totalInfo.pay }}</div>
                                        </td>
                                    </tr>
                                    <template v-for="(totalDetail, totalDetailIndex) in item.totalDetailInfos">
                                        <tr :key="totalDetail.date + totalDetailIndex + totalDetail.type_name">
                                            <td @click="showDataDrills(totalDetail,totalDetailIndex+6,2,null)" v-if="isDrill">
                                                <div><a href="javascript:;">{{ totalDetail.pay }}</a></div>
                                            </td>
                                            <td v-else>
                                                <div>{{ totalDetail.pay }}</div>
                                            </td>
                                        </tr>
                                    </template>
                                    <tr>
                                        <td @click="showDataDrills(item.totalInfo,10,1,null)" v-if="isDrill">
                                            <div><a href="javascript:;">{{ item.totalInfo.pay_inner }}</a></div>
                                        </td>
                                        <td v-else>
                                            <div>{{ item.totalInfo.pay_inner }}</div>
                                        </td>
                                    </tr>
                                    <template v-for="(totalDetail, totalDetailIndex) in item.totalDetailInfos">
                                        <tr :key="totalDetail.date + totalDetailIndex + 'payinner' + totalDetail.type_name">
                                            <td @click="showDataDrills(totalDetail,totalDetailIndex+11,3,null)" v-if="isDrill">
                                                <div><a href="javascript:;">{{ totalDetail.pay_inner }}</a></div>
                                            </td>
                                            <td v-else>
                                                <div>{{ totalDetail.pay_inner }}</div>
                                            </td>
                                        </tr>
                                    </template>
                                    <tr>
                                        <td @click="showDataDrills(item.totalInfo,15,1,null)" v-if="isDrill">
                                            <div><a href="javascript:;">{{ item.totalInfo.balance_end }}</a></div>
                                        </td>
                                        <td v-else>
                                            <div>{{ item.totalInfo.balance_end }}</div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td @click="showDataDrills(item.totalInfo,16,1,null)" v-if="isDrill">
                                            <div><a href="javascript:;">{{ item.totalInfo.paycycle1 }}</a></div>
                                        </td>
                                        <td v-else>
                                            <div>{{ item.totalInfo.paycycle1 }}</div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td @click="showDataDrills(item.totalInfo,17,1,null)" v-if="isDrill">
                                            <div><a href="javascript:;">{{ item.totalInfo.paycycle3 }}</a></div>
                                        </td>
                                        <td v-else>
                                            <div>{{ item.totalInfo.paycycle3 }}</div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td @click="showDataDrills(item.totalInfo,18,1,null)" v-if="isDrill">
                                            <div><a href="javascript:;">{{ item.totalInfo.paycycle6 }}</a></div>
                                        </td>
                                        <td v-else>
                                            <div>{{ item.totalInfo.paycycle6 }}</div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td @click="showDataDrills(item.totalInfo,19,1,null)" v-if="isDrill">
                                            <div><a href="javascript:;">{{ item.totalInfo.balance_end_inner }}</a></div>
                                        </td>
                                        <td v-else>
                                            <div>{{ item.totalInfo.balance_end_inner }}</div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td @click="showDataDrills(item.totalInfo,20,1,null)" v-if="isDrill">
                                            <div><a href="javascript:;">{{ item.totalInfo.pay_rate }}</a></div>
                                        </td>
                                        <td v-else>
                                            <div>{{ item.totalInfo.pay_rate }}</div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td @click="showDataDrills(item.totalInfo,21,1,null)" v-if="isDrill">
                                            <div><a href="javascript:;">{{ item.totalInfo.pay_reduce }}</a></div>
                                        </td>
                                        <td v-else>
                                            <div>{{ item.totalInfo.pay_reduce }}</div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                    </template>
                </tr>
                <!-- 其他 -->
                <template v-for="(project, projectIndex) in projectsData">
                    <tr :key="projectIndex" :class="{'border6': projectIndex<projectsData.length-1}">
                        <td class="custom-th" :class="{'custom-th-odd':projectIndex%2===1,'custom-th-even':projectIndex%2===0}">
                            {{ projectIndex+2 }}
                        </td>
                        <td class="custom-th">
                            应付账款--{{ project.label }}
                        </td>
                        <td class="padding0">
                            <table class="custom-table inner-table">
                                <tbody>
                                    <tr>
                                        <td class="td-class1">
                                            期初余额（万元）
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="td-class2">
                                            其中：中联内应付
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="td-class1">
                                            实际采购（万元）
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="td-class2">
                                            其中：中联内采购
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="td-class1">
                                            实际付款（万元）
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="td-class2">
                                            现金（万元）
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="td-class2">
                                            承兑（万元）
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="td-class2">
                                            抵账（万元）
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="td-class2">
                                            其它（万元）
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="td-class2">
                                            其中：中联内付款
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="td-class3">
                                            现金（万元）
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="td-class3">
                                            承兑（万元）
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="td-class3">
                                            抵账（万元）
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="td-class3">
                                            其它（万元）
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="td-class1">
                                            期末余额（万元）
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="td-right">
                                            付款周期：1月内
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="td-right">
                                            3月内
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="td-right">
                                            6月内
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="td-right">
                                            其中：中联内应付
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="td-class1">
                                            付款比例（％）
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="td-class1">
                                            应付减少(万元)
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                        <template v-for="(item, index) in project.projectReports">
                            <td :key="'project'+index" class="padding0">
                                <table class="custom-table inner-table">
                                    <tbody>
                                        <tr>
                                            <td @click="showDataDrills(item.typeInfo,1,4,projectIndex)" v-if="isDrill">
                                                <div><a href="javascript:;">{{ item.typeInfo.balance_begin }}</a></div>
                                            </td>
                                            <td v-else>
                                                <div>{{ item.typeInfo.balance_begin }}</div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td @click="showDataDrills(item.typeInfo,2,4,projectIndex)" v-if="isDrill">
                                                <div><a href="javascript:;">{{ item.typeInfo.balance_begin_inner }}</a></div>
                                            </td>
                                            <td v-else>
                                                <div>{{ item.typeInfo.balance_begin_inner }}</div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td @click="showDataDrills(item.typeInfo,3,4,projectIndex)" v-if="isDrill">
                                                <div><a href="javascript:;">{{ item.typeInfo.procure_actual }}</a></div>
                                            </td>
                                            <td v-else>
                                                <div>{{ item.typeInfo.procure_actual }}</div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td @click="showDataDrills(item.typeInfo,4,4,projectIndex)" v-if="isDrill">
                                                <div><a href="javascript:;">{{ item.typeInfo.procure_actual_inner }}</a></div>
                                            </td>
                                            <td v-else>
                                                <div>{{ item.typeInfo.procure_actual_inner }}</div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td @click="showDataDrills(item.typeInfo,5,4,projectIndex)" v-if="isDrill">
                                                <div><a href="javascript:;">{{ item.typeInfo.pay }}</a></div>
                                            </td>
                                            <td v-else>
                                                <div>{{ item.typeInfo.pay }}</div>
                                            </td>
                                        </tr>
                                        <template v-for="(typeDetail, typeDetailIndex) in item.typeDetailInfos">
                                            <tr :key="typeDetail.date + typeDetailIndex + typeDetail.type_name">
                                                <td @click="showDataDrills(typeDetail,typeDetailIndex+6,5,projectIndex)" v-if="isDrill">
                                                    <div><a href="javascript:;">{{ typeDetail.pay }}</a></div>
                                                </td>
                                                <td v-else>
                                                    <div>{{ typeDetail.pay }}</div>
                                                </td>
                                            </tr>
                                        </template>
                                        <tr>
                                            <td @click="showDataDrills(item.typeInfo,10,4,projectIndex)" v-if="isDrill">
                                                <div><a href="javascript:;">{{ item.typeInfo.pay_inner }}</a></div>
                                            </td>
                                            <td v-else>
                                                <div>{{ item.typeInfo.pay_inner }}</div>
                                            </td>
                                        </tr>
                                        <template v-for="(typeDetail, typeDetailIndex) in item.typeDetailInfos">
                                            <tr :key="typeDetail.date + typeDetailIndex + 'payinner' + typeDetail.type_name">
                                                <td @click="showDataDrills(typeDetail,typeDetailIndex+11,6,projectIndex)" v-if="isDrill">
                                                    <div><a href="javascript:;">{{ typeDetail.pay_inner }}</a></div>
                                                </td>
                                                <td v-else>
                                                    <div>{{ typeDetail.pay_inner }}</div>
                                                </td>
                                            </tr>
                                        </template>
                                        <tr>
                                            <td @click="showDataDrills(item.typeInfo,15,4,projectIndex)" v-if="isDrill">
                                                <div><a href="javascript:;">{{ item.typeInfo.balance_end }}</a></div>
                                            </td>
                                            <td v-else>
                                                <div>{{ item.typeInfo.balance_end }}</div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td @click="showDataDrills(item.typeInfo,16,4,projectIndex)" v-if="isDrill">
                                                <div><a href="javascript:;">{{ item.typeInfo.paycycle1 }}</a></div>
                                            </td>
                                            <td v-else>
                                                <div>{{ item.typeInfo.paycycle1 }}</div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td @click="showDataDrills(item.typeInfo,17,4,projectIndex)" v-if="isDrill">
                                                <div><a href="javascript:;">{{ item.typeInfo.paycycle3 }}</a></div>
                                            </td>
                                            <td v-else>
                                                <div>{{ item.typeInfo.paycycle3 }}</div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td @click="showDataDrills(item.typeInfo,18,4,projectIndex)" v-if="isDrill">
                                                <div><a href="javascript:;">{{ item.typeInfo.paycycle6 }}</a></div>
                                            </td>
                                            <td v-else>
                                                <div>{{ item.typeInfo.paycycle6 }}</div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td @click="showDataDrills(item.typeInfo,19,4,projectIndex)" v-if="isDrill">
                                                <div><a href="javascript:;">{{ item.typeInfo.balance_end_inner }}</a></div>
                                            </td>
                                            <td v-else>
                                                <div>{{ item.typeInfo.balance_end_inner }}</div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td @click="showDataDrills(item.typeInfo,20,4,projectIndex)" v-if="isDrill">
                                                <div><a href="javascript:;">{{ item.typeInfo.pay_rate }}</a></div>
                                            </td>
                                            <td v-else>
                                                <div>{{ item.typeInfo.pay_rate }}</div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td @click="showDataDrills(item.typeInfo,21,4,projectIndex)" v-if="isDrill">
                                                <div><a href="javascript:;">{{ item.typeInfo.pay_reduce }}</a></div>
                                            </td>
                                            <td v-else>
                                                <div>{{ item.typeInfo.pay_reduce }}</div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </template>
                    </tr>
                </template>
            </tbody>
        </table>
    </div>
</template>

<script>
export default {
    // 应付情况
    name: 'accounts-payable',
    components: {},
    props: {
        loadObj: {
            type: [Object],
        },
        reportData: {
            type: [Object],
        },
        queryStr: {
            type: [String],
        },
    },
    data() {
        return {
            totalData: [
                { totalInfo: {} },
            ],
            projectsData: [],
            isDrill: true,
        };
    },
    watch: {
        queryStr: function () {
            this.getTotal();
        },
    },
    computed: {},
    methods: {
        // 获取-总计
        getTotal() {
            this.$axios
                .get('/interfaceApi/report/payable/report/total/' + this.queryStr)
                .then(res => {
                    if (res) {
                        this.totalData = res;
                        this.getProjectsData();
                    }
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        getProjectsData() {
            this.$axios
                .get('/interfaceApi/report/payable/report/project/' + this.queryStr)
                .then(res => {
                    if (res) {
                        this.projectsData = res;
                        // this.loadObj.close();
                    }
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 数据钻取
        /* eslint-disable */
        showDataDrills(rowData, field, dataType, payable) {
            let parseField = null;
            let parsetText = null;
            let name = null;
            let showType = null;
            switch (field) {
                case 1:
                    parseField = 1;
                    parsetText = '期初余额(万元)';
                    break;
                case 2:
                    parseField = 2;
                    parsetText = '中联内应付';
                    break;
                case 3:
                    parseField = 3;
                    parsetText = '实际采购(万元)';
                    break;
                case 4:
                    parseField = 4;
                    parsetText = '中联内采购';
                    break;
                case 5:
                    parseField = 5;
                    parsetText = '实际付款(万元)';
                    break;
                case 6:
                    parsetText = '现金(万元)';
                    name = 0;
                    break;
                case 7:
                    parsetText = '承兑(万元)';
                    name = 1;
                    break;
                case 8:
                    parsetText = '抵账(万元)';
                    name = 2;
                    break;
                case 9:
                    parsetText = '其它(万元)';
                    name = 3;
                    break;
                case 10:
                    parseField = 6;
                    parsetText = '中联内付款';
                    name = 3;
                    break;
                case 11:
                    parsetText = '现金(万元)';
                    name = 0;
                    break;
                case 12:
                    parsetText = '承兑(万元)';
                    name = 1;
                    break;
                case 13:
                    parsetText = '抵账(万元)';
                    name = 2;
                    break;
                case 14:
                    parsetText = '其它(万元)';
                    name = 3;
                    break;
                case 15:
                    parseField = 7;
                    parsetText = '期末余额(万元)';
                    break;
                case 16:
                    parseField = 8;
                    parsetText = '付款周期:1月内';
                    break;
                case 17:
                    parseField = 9;
                    parsetText = '付款周期:3月内';
                    break;
                case 18:
                    parseField = 10;
                    parsetText = '付款周期:6月内';
                    break;
                case 19:
                    parseField = 11;
                    parsetText = '中联内应付';
                    break;
                case 20:
                    parseField = 12;
                    parsetText = '付款比例(％)';
                    showType = 1;
                    break;
                case 21:
                    parseField = 13;
                    parsetText = '应付减少(万元)';
                    break;
            }
            let istotal = 0;
            let month = 0;
            if (rowData.date === 0) {
                istotal = 1;
                if (this.reportData.month === 0) {
                    month = 12;
                } else {
                    month = this.reportData.month;
                }
            } else {
                istotal = 0;
                month = rowData.date;
            }
            const params = {
                field: parseField,
                text: parsetText,
                year: this.reportData.year,
                month: month,
                business_code: 'yyqk',
                istotal: istotal,
                treeid: this.reportData.treeid,
                type: dataType,
                payable: payable,
                name: name,
                firstPop: true,
                sourceReport: 'accountsPayable',
                showType: showType,
            };
            const pop = this.$toast({
                title: false,
                t_url: 'reportManage/operationTrends/summary/dataDrills/DrillDownPop',
                type: 'eject',
                width: '80%',
                extr: { closeBtn: { width: '.6rem', height: '.6rem', 'line-height': '.6rem' }, params: params },
            });
            window.window.$globalHub.$store.commit('setChartPop', pop);
        },
        isDrillFn() {
            if (this.reportData.org_type === 5 || this.reportData.org_type === 6) {
                this.isDrill = false;
            } else {
                this.isDrill = true;
            }
        },
    },
    created() {
        this.getTotal();
    },
    mounted() {
        this.isDrillFn();
    },
};
</script>
<style>
    /* .main .main-bottom .edit-table .wrapper .custom-table td, .main .main-bottom .edit-table .wrapper .custom-table th{
        text-align: left;
        padding-left: .1rem;
    } */
    .padding20{
        padding-left: .2rem!important;
    }
    .padding30{
        padding-left: .3rem!important;
    }
</style>